import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import GroceryList from './components/GroceryList';
import DiwaliList from './components/DiwaliList';
import ModularKitchenList from './components/ModulerKitchenList';
import './App.css';
import HomeSanitywareList from './components/HomeSanitywareList';
import ElectricalFittingItemsList from './components/ElectricalFittingItemsList';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<GroceryList />} />
                <Route path="/kirana-saman-list" element={<GroceryList />} />
                <Route path="/diwali-saman-list" element={<DiwaliList />} />
                <Route path="/modular-kitchen-furniture-saman-list" element={<ModularKitchenList />} />
                <Route path="/home-sanity-saman-list" element={<HomeSanitywareList />} />
                <Route path="/electrical-fitting-saman-list" element={<ElectricalFittingItemsList />} />
                {/* Add more routes here for other lists */}

                {/* Catch-all route: If no route matches, redirect to the home page */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
}

export default App;